
import { defineComponent, reactive, ref, toRaw, UnwrapRef, onMounted } from 'vue';
import { Moment } from 'moment';
import { Modal, message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { RuleObject, ValidateErrorEntity } from 'ant-design-vue/es/form/interface';
import DetailForm from './DetailForm.vue';
import { channelListEnum } from '../dict';

// type cardModel = {
//   company: string,
//   activityName: string,
//   discount: string,
//   money: number | string,
//   xxx5: number | string,
//   channel: string[] | string,
//   num: number | string,
//   dateType: number | string,
//   date1: Moment | string,
//   date2: Moment | string,
//   date3: Moment | string,
//   date4: Moment | string,
//   [propName:string]: any
// }
export default defineComponent({
  components: {
    DetailForm,
  },
  emits: ['close'],
  setup(props, ctx) {
    const visible = ref<boolean>(true);
    const loading = ref<boolean>(false);

    const showModal = () => {
      visible.value = true;
    };

    const formRef = ref();
    // const cardModel: UnwrapRef<cardModel> = reactive({
    //   company: '',
    //   activityName: '',
    //   discount: '',
    //   money: '',
    //   xxx5: '',
    //   channel: '',
    //   num: '',
    //   dateType: '',
    //   date1: '',
    //   date2: '',
    //   date3: '',
    //   date4: '',
    // });
    const cardModel = reactive({
      enterpriseId: '',
      enterpriseName: '',
      activityName: '',
      discountType: 'FULL_REDUCE',
      fullAmount: '',
      reduceAmount: '',
      useChannel: [],
      useChannelDetail: [],
      quantity: '',
      fixedTermFlag: 1,
      effectiveStartDate: '',
      effectiveEndDate: '',
      effectiveDays: '',
      lastedEffectiveDate: '',
    });
    const checkEnterpriseId = async (rule: RuleObject, value: number) => {
      if (!value) {
        return Promise.reject('请选择');
      }
      return Promise.resolve();
    };
    const checkAmount = async (rule: RuleObject, value: number) => {
      const { fullAmount, reduceAmount } = cardModel;
      if (fullAmount && reduceAmount) {
        if (+reduceAmount > +fullAmount) {
          return Promise.reject('满减金额需大于优惠券面额');
        }
        return Promise.resolve();
      } 
      return Promise.reject('请输入');
    };
    const checkEffectiveDate = async (rule: RuleObject, value: number) => {
      const { effectiveStartDate, effectiveEndDate } = cardModel;
      if (effectiveEndDate && effectiveStartDate) {
        if (effectiveStartDate > effectiveEndDate) {
          return Promise.reject('生效时间大于失效时间');
        }
        return Promise.resolve();
      } 
      return Promise.resolve();
    };
    const rules = {
      enterpriseId: [{ required: true, validator: checkEnterpriseId, trigger: 'change' }],
      activityName: [{ required: true, message: '请输入', trigger: 'blur' }],
      discountType: [{ required: true, message: '请选择', trigger: 'blur' }],
      fullAmount: [{ required: true, validator: checkAmount, trigger: 'change' }],
      useChannel: [{ required: true, message: '请选择使用渠道', trigger: 'blur', type: 'array' }],
      useChannelDetail: [{ required: true, message: '请选择加油类型', trigger: 'blur', type: 'array' }],
      quantity: [{ required: true, message: '请输入', trigger: 'blur', type: 'number' }],
      effectiveStartDate: [{ required: true, message: '请选择', trigger: 'blur' }, { validator: checkEffectiveDate, trigger: 'blur' }],
      effectiveEndDate: [{ required: true, message: '请选择', trigger: 'change' }, { validator: checkEffectiveDate, trigger: 'blur' }],
      effectiveDays: [{ required: true, message: '请输入', trigger: 'blur', type: 'number' }],
      lastedEffectiveDate: [{ required: true, message: '请选择', trigger: 'blur' }],
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };

    // 获取渠道
    const channelObj: Record<string, string> = reactive({});
    const allChannels = ref<Array<string>>([]);
    const getChannel = async () => {
      try {
        const { data } = await request.get(createApiUrl('/newlinkSass/discountCard/get-using-channels'), { params: { enterpriseId: cardModel.enterpriseId } });

        const list = ref<Array<string>>([]);
        data.forEach((item: any) => {
          const { channelCode, detail } = item;
          if (channelCode === 'REFUEL' && detail) {
            Object.keys(channelListEnum).forEach(key => {
              if (detail.includes(key)) {
                channelObj[key] = channelListEnum[key];
              }
            });
          }
          list.value.push(channelCode);
        });
        allChannels.value = list.value;

        cardModel.useChannel = [];
        cardModel.useChannelDetail = [];
      } catch (e:any) {
        message.error(e.message);
      }
    };

    const showDetail = ref<boolean>(false);
    const lastStep = () => {
      showDetail.value = false;
    };
    const detailInfo:Record<string, any> = ref({
      settlementPrice: '',
      totalSettlementPrice: '',
      totalDenomination: '',
    });

    let enterpriseObj = reactive({
      enterpriseName: '',
    });
    const changeEnterprise = (val:any) => {
      if (val) {
        getChannel();
        enterpriseObj = val;
      } else {
        enterpriseObj = {
          enterpriseName: '',
        };
      }
    };
    const nextStep = () => {
      formRef.value
        .validate()
        .then(async () => {
          cardModel.enterpriseName = enterpriseObj.enterpriseName;
          const { enterpriseId, quantity, fullAmount, reduceAmount } = cardModel;
          try {
            const { data } = await request.post(createApiUrl('/newlinkSass/discountCardRule/calSettlementInfo'), { enterpriseId, quantity, fullAmount, reduceAmount });
            const { settlementPrice, totalSettlementPrice, totalDenomination } = data;
            detailInfo.value = { ...cardModel };
            detailInfo.value.settlementPrice = settlementPrice;
            detailInfo.value.totalSettlementPrice = totalSettlementPrice;
            detailInfo.value.totalDenomination = totalDenomination;
            showDetail.value = true;
          } catch (e:any) {
            message.error(e.message);
          }
        });
    };

    const handleOk = (e: MouseEvent) => {
      loading.value = true;
      request.post(createApiUrl('/newlinkSass/discountCardRule'), { ...cardModel, useChannel: cardModel.useChannel.join(',') })
        .then(() => {
          Modal.success({
            title: '操作结果',
            content: '折扣卡生产申请提交成功',
            okText: '知道了',
            onOk() {
              ctx.emit('close', true);
            },
          });
        }).catch(e => {
          message.error(e.message);
        }).finally(() => {
          loading.value = false;
        });
    };
    const handleCancel = (type:string) => {
      if (type === 'modal') {
        ctx.emit('close');
        return;
      }
      Modal.confirm({
        title: '提示',
        content: '确认退出员工折扣卡申请?',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          ctx.emit('close');
        },
      });
    };

    return {
      visible,
      loading,
      showModal,
      handleOk,
      handleCancel,
      formRef,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      cardModel,
      rules,
      resetForm,
      showDetail,
      lastStep,
      nextStep,
      enterpriseObj,
      channelObj,
      detailInfo,
      changeEnterprise,
      allChannels,
    };
  },
});
