
import { request } from '@/utils/request';
import { defineComponent, reactive, ref, watch, h } from 'vue';
import { createApiUrl } from '@/utils/utils';
import { message, Modal } from 'ant-design-vue';
import { channelText } from '../dict';


type userInfoType = {
  userName: string
  userCode: string
  phone: string
}
export default defineComponent({
  props: {
    confirmInfo: { type: Object, default: () => ({}) },
  },
  emits: ['close'],
  setup(props, ctx) {
    const visible = ref<boolean>(true);
    const loading = ref<boolean>(false);
    const handleCancel = () => {
      ctx.emit('close');
    };
    
    let selectedUser = reactive<userInfoType>({
      userName: '',
      userCode: '',
      phone: '',
    });

    const getUserInfo = (val: userInfoType) => {
      if (val) {
        selectedUser = val;
      } else {
        selectedUser = {
          userName: '',
          userCode: '',
          phone: '',
        };
      }
    };
    const formRef = ref();
    const formState = reactive({
      userCode: undefined,
    });
    const onSubmit = () => {
      loading.value = true;
      const { userName, userCode, phone } = selectedUser;
      const data = {
        discountCardRuleId: props.confirmInfo.ruleId,
        discountCardId: props.confirmInfo.id,
        userName,
        userCode,
        phone,
      };
      request.post(createApiUrl('/newlinkSass/discountCard/allocation'), data)
        .then(() => {
          Modal.success({
            title: '操作成功',
            content: `折扣卡${props.confirmInfo.discountCardId} 已成功分配给 ${userName} (${phone})`,
            okText: '知道了',
            onOk() {
              ctx.emit('close', true);
            },
          });
        }).catch(e => {
          message.error(e.message);
        }).finally(() => {
          loading.value = false;
        });
    };

    const handleOk = () => {
      formRef.value.validate()
        .then(() => {
          onSubmit();
        });
    };
    
    return {
      visible,
      loading,
      formRef,
      formState,
      selectedUser,
      handleCancel,
      handleOk,
      getUserInfo,
      channelText,
    };
  },
});
