
import { defineComponent, ref } from 'vue';
import { discountTypeEnum, channelTypeEnum, channelListEnum, channelText } from '../dict';

// type detailInfo = {
//   name: string;
//   region: string | undefined;
//   date1: Moment | undefined;
//   delivery: boolean;
//   type: string[];
//   resource: string;
//   desc: string;
//   [propName:string]: any
// }
export default defineComponent({
  props: {
    detailInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const info = ref(props.detailInfo);
    const showChannel = () => {
      const { useChannel } = props.detailInfo;
      const tempKey = useChannel && Array.isArray(useChannel) ? useChannel[0] : useChannel;
      return tempKey ? channelTypeEnum[tempKey] : '';
    };
    const showChannelList = () => {
      const { useChannel } = props.detailInfo;
      const tempKey = useChannel && Array.isArray(useChannel) ? useChannel[0] : useChannel;
      return tempKey ? channelListEnum[tempKey] : '';
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      discountTypeEnum,
      showChannel,
      showChannelList,
      info,
      channelText,
    };
  },
});
