
// 渠道类别
export const channelTypeEnum: Record<string, string> = {
  REFUEL: '加油',
  ELECTRICITY: '充电',
  CARMAINTENANCE: '养车',

};
// 渠道
export const channelListEnum: Record<string, string> = {
  GASOLINE: '汽油',
  DIESEL: '柴油',
  NATURAL_GAS: '天然气',
  // ELECTRICITY: '电',
};
// 折扣类型
export const discountTypeEnum: Record<string, string> = {
  FULL_REDUCE: '满减',
};
// 审核状态
export const auditStatusEnum: Record<string, string> = {
  REVIEW: '待审核',
  REVIEWED: '审核通过',
  NO_NEED_REVIEW: '无需审核',
  CANCEL: '已作废',
  REVOKE: '已撤销',
  FAILURE: '审核拒绝',
};
// 卡状态
export const cardStatusEnum: Record<string, string> = {
  WAIT_ALLOCATION: '待分配',
  WAIT_CONSUME: '待消费',
  HAS_CONSUME: '已消费',
  EXPIRE: '已过期',
  CANCELED: '已作废',
  IN_CONSUME: '预消费',
};

export const channelEnum = [
  { val: 'GASOLINE', label: '汽油' },
  { val: 'DIESEL', label: '柴油' },
  { val: 'NATURAL_GAS', label: '天然气' },
];

export const getUseChannelList = (row: Record<string, any>) : Array<string> => {
  if (!row || !row.useChannel) return [];
  const { useChannel, useChannelDetail } = row;
  if (Array.isArray(useChannel)) return useChannel;
  if (useChannelDetail && useChannelDetail.includes('ELECTRICITY')) return [useChannel, 'ELECTRICITY'];
  return useChannel.split(',');
};

export const showChannelList = (row: Record<string, any>) => {
  if (!row || !row.useChannelDetail) return '';
  const list = channelEnum.filter(item => row.useChannelDetail.includes(item.val));
  return list.map(item => item.label).join(',');
};

export const channelText = (row: Record<string, any>) => {
  let result = '';
  const useChannelList = getUseChannelList(row);
  useChannelList.forEach((channel, index) => {
    result += channelTypeEnum[channel];
    if (channel === 'REFUEL') result += showChannelList(row) ? `(${showChannelList(row)})` : '';
    if (index !== useChannelList.length - 1) result += '、';
  });
  return result;
};
