
import { defineComponent, computed, reactive, ref, onMounted, toRefs, createVNode, watch, isRef, isReactive, h } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { noEmptyProp, not } from '@/utils/illuminate';
import { useRouter } from 'vue-router';
import type { TableColumn } from '@/components/Table';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { request } from '@/utils/request';
import moment from 'moment';
import { useState } from '@/store';
import AddCardModal from './parts/AddCardModal.vue';
import DistributeModal from './parts/DistributeModal.vue';
import { discountTypeEnum, channelListEnum, auditStatusEnum, channelText, channelTypeEnum } from './dict';


export default defineComponent({
  name: 'DiscountCardBatch',
  components: {
    AddCardModal,
    DistributeModal,
  },
  setup(props, ctx) {
    const state = useState();
    const router = useRouter();

    const isStateLoading = ref(false);
    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态

    const filterParams = reactive({
      batchId: '',
      enterpriseId: '',
      approveStatus: '',
      applyTime: [],
      useChannel: '',
    });

    const transformTime = (prefix:string, arr: Array<string>) => {
      if (Array.isArray(arr) && arr.length === 2) {
        const start = `${prefix}StartTime`;
        const end = `${prefix}EndTime`;
        return { [start]: moment(arr[0]).format('YYYY-MM-DD 00:00:00'), [end]: moment(arr[1]).format('YYYY-MM-DD 23:59:59') };
      }
      return {};
    };

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      const applyTime = transformTime('apply', filterParams.applyTime);
      request.post(
        createApiUrl('/newlinkSass/discountCardRule/list'),
        { ...parameter, ...noEmptyProp(not(filterParams, ['applyTime'])), ...applyTime, loginEnterpriseId: state.enterprise.value.enterpriseId },
        { noEnterpriseId: true },
      )
        .then((res: any) => {
          const { data } = res;
          isStateLoading.value = false;
          dataPage.value = data.list; // 当前Table数据
          dataState.value = true; // 数据第一次加载
          resolve(data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };
    const showChannelList = (row: Record<string, any>) => row.useChannelDetail.map((key:string) => channelListEnum[key as keyof typeof channelListEnum]).join(',');
    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '批次ID', dataIndex: 'batchId', align: 'center' },
        { title: '所属企业', dataIndex: 'enterpriseName', align: 'center' },
        { title: '活动名称', dataIndex: 'activityName', align: 'center' },
        { title: '折扣类型', dataIndex: 'discountType', align: 'center', customRender: ({ text }): string => discountTypeEnum[text as keyof typeof discountTypeEnum] },
        { title: '面额(元)', dataIndex: 'reduceAmount', align: 'center' },
        { title: '使用条件', dataIndex: 'fullAmount', align: 'center', customRender: ({ text }) => `满 ${text || 0} 元` },
        { title: '使用渠道', dataIndex: 'useChannel', align: 'center', customRender: ({ text, record }) => channelText(record) },
        { title: '结算价(元)', dataIndex: 'settlementPrice', align: 'center' },
        { title: '数量(张)', dataIndex: 'quantity', align: 'center' },
        { title: '有效期', dataIndex: 'effectiveDays', align: 'center', slots: { customRender: 'effectiveDays' } },
        { title: '最晚分配时间', dataIndex: 'lastedEffectiveDate', align: 'center' },
        { title: '申请人', dataIndex: 'applyUserName', align: 'center' },
        { title: '申请时间', dataIndex: 'applyTime', align: 'center' },
        { title: '审批人', dataIndex: 'reviewUserName', align: 'center' },
        { title: '审批时间', dataIndex: 'reviewTime', align: 'center' },
        { title: '状态', dataIndex: 'approveStatus', align: 'center', customRender: ({ text }): string => auditStatusEnum[text as keyof typeof auditStatusEnum] },
        { title: '操作', dataIndex: 'action', width: '180px', align: 'center', slots: { customRender: 'action' }, fixed: 'right' },
      ];
      return basic;
    });

    const getContent = (row:any, tips: string, type: string, detail?: any) => h('div', null, [
      h('p', null, tips),
      h('div', null, [
        h('label', null, '批次ID: '),
        h('span', null, `${row.batchId}`),
      ]),
      h('div', null, [
        h('label', null, '企业: '),
        h('span', null, `${row.enterpriseName}`),
      ]),
      h('div', null, [
        h('label', null, '使用渠道: '),
        h('span', null, `${channelText(type !== 'invalid' ? row : detail)}`),
      ]),
      h('div', null, [
        h('label', null, '面额: '),
        h('span', null, `满 ${row.fullAmount} 元减 ${row.reduceAmount} 元`),
      ]),
      h('div', null, [
        h('label', null, '数量: '),
        h('span', null, `${row.quantity}张 ${type === 'invalid' ? `（未使用${row.notUsedQuantity}张）` : ''}`),
      ]),
    ]);
    // 作废
    const onInvalid = (row: any) => {
      request.post(createApiUrl('/newlinkSass/discountCardRule/pre_cancel_check'), { discountCarRuledId: row.id }).then(({ data }) => {
        Modal.confirm({
          title: '操作确认',
          content: getContent(data, '确认作废该批次内未使用的员工折扣卡', 'invalid', row),
          okText: '确认',
          cancelText: '取消',
          onOk() {
            request.post(createApiUrl('/newlinkSass/discountCardRule/cancel'), { discountCardRuleId: row.id, approveStatus: 'CANCEL' })
              .then(() => {
                onRefreshTable();
                Modal.success({
                  title: '操作成功',
                  content: `已成功作废${data.notUsedQuantity}张员工折扣卡`,
                  okText: '知道了',
                  onOk() {
                    onRefreshTable();
                  },
                });
              }).catch(e => {
                message.error(e.message);
              });
          },
        });
      });
    };
    // 撤销
    const onCancel = (row: any) => {
      Modal.confirm({
        title: '操作确认',
        content: getContent(row, '确认撤销该批次折扣卡申请', 'cancel'),
        okText: '确认撤销',
        cancelText: '取消',
        onOk() {
          request.post(createApiUrl('/newlinkSass/discountCardRule/approve'), { discountCardRuleId: row.id, approveStatus: 'REVOKE' })
            .then(() => {
              onRefreshTable();
              Modal.success({
                title: '操作成功',
                content: '已成功撤销该批次折扣卡申请',
                okText: '知道了',
                onOk() {
                  onRefreshTable();
                },
              });
            }).catch(e => {
              message.error(e.message);
            });
        },
      });
    };
    const goBatchDetail = (row: Record<string, any>) => {
      router.push({ name: 'discountCard.batchDetail', query: { id: row.id } });
    };

    const addCardRef = ref<any>(null);
    const showModal = ref<boolean>(false);
    const onShowCard = () => {
      showModal.value = true;
    };
    const closeModal = (isRefresh = false) => {
      showModal.value = false;
      if (isRefresh) {
        onRefreshTable();
      }
    };

    // showDistributeModal
    const showDistributeModal = ref<boolean>(false);
    const closeDistributeModal = (isRefresh = false) => {
      showDistributeModal.value = false;
      if (isRefresh) {
        onRefreshTable();
      }
    };

    const batchInfo = ref({});
    const onDistribute = (row: Record<string, any>) => {
      batchInfo.value = row;
      showDistributeModal.value = true;
    };

    const isShowApplyBtn = ref<boolean>(false);
    const checkApplyRole = () => {
      request.get(
        createApiUrl('/newlinkSass/discountCardRule/checkApplyRole'),
        { params: { applyPath: 'SAAS' } },
      ).then(res => {
        isShowApplyBtn.value = res.data;
      });
    };

    onMounted(() => {
      checkApplyRole();
    });
    return {
      filterParams,
      tableRef,
      tableColumns,
      dataPage,
      dataState,

      loadTableData,
      onRefreshTable,

      isStateLoading,

      onInvalid,
      
      addCardRef,
      showModal,
      onShowCard,
      closeModal,
      showDistributeModal,
      closeDistributeModal,
      onDistribute,
      batchInfo,

      auditStatusEnum,

      onCancel,
      goBatchDetail,

      isShowApplyBtn,
      sldPageTitle,
      channelTypeEnum,
    };
  },
});
