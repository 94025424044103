
import { defineComponent, computed, reactive, ref, h } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { noEmptyProp, not, dispatchDownload } from '@/utils/illuminate';
import { useRouter } from 'vue-router';
import type { TableColumn } from '@/components/Table';
import { createApiUrl, sldPageTitle } from '@/utils/utils';
import { request } from '@/utils/request';
import { useState } from '@/store';
import moment, { Moment } from 'moment';
import { channelTypeEnum, discountTypeEnum, channelListEnum, cardStatusEnum, channelText } from './dict';
import ConfrimModal from './parts/ConfirmModal.vue';


export default defineComponent({
  name: 'DiscountCard',
  components: {
    ConfrimModal,
  },
  setup(props, ctx) {
    const router = useRouter();
    const state = useState();

    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态

    const filterParams = reactive({
      discountCardId: '',
      batchId: '',
      enterpriseId: '',
      activityName: '',
      allocationPerson: '',
      orderCode: '',
      produceTime: [],
      allocationTime: [],
      orderTime: [],
      expireTime: [],
      cancelTime: [],
      status: '',
      useChannel: '',
    });

    const transformTime = (prefix:string, arr: Array<string>) => {
      if (Array.isArray(arr) && arr.length === 2) {
        const start = `${prefix}StartTime`;
        const end = `${prefix}EndTime`;
        return { [start]: moment(arr[0]).format('YYYY-MM-DD 00:00:00'), [end]: moment(arr[1]).format('YYYY-MM-DD 23:59:59') };
      }
      return {};
    };
    const getParams = () => {
      let listParams: Record<string, any> = {};
      const produceTime = transformTime('produce', filterParams.produceTime);
      const allocationTime = transformTime('allocation', filterParams.allocationTime);
      const orderTime = transformTime('order', filterParams.orderTime);
      const expireTime = transformTime('expire', filterParams.expireTime);
      const cancelTime = transformTime('cancel', filterParams.cancelTime);
      listParams = {
        loginEnterpriseId: state.enterprise.value.enterpriseId,
        ...noEmptyProp(not(filterParams, ['produceTime', 'allocationTime', 'orderTime', 'expireTime', 'cancelTime'])),
        ...produceTime,
        ...allocationTime,
        ...orderTime,
        ...expireTime,
        ...cancelTime,
      };
      return listParams;
    };

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      request.post(createApiUrl('/newlinkSass/discountCard/list'), 
        { ...parameter, ...getParams() }, 
        { noEnterpriseId: true })
        .then((res: any) => {
          const { data } = res;
          dataPage.value = data.list; // 当前Table数据
          dataState.value = true; // 数据第一次加载
          resolve(data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });
    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };
    const showChannelList = (row: Record<string, any>) => row.useChannelDetail.map((key:string) => channelListEnum[key as keyof typeof channelListEnum]).join(',');
    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '折扣卡ID', dataIndex: 'discountCardId', align: 'center' },
        { title: '批次ID', dataIndex: 'batchId', align: 'center' },
        { title: '所属企业', dataIndex: 'enterpriseName', align: 'center' },
        { title: '活动名称', dataIndex: 'activityName', align: 'center' },
        { title: '折扣类型', dataIndex: 'discountType', align: 'center', customRender: ({ text }): string => discountTypeEnum[text as keyof typeof discountTypeEnum] },
        { title: '面额(元)', dataIndex: 'reduceAmount', align: 'center' },
        { title: '使用条件', dataIndex: 'fullAmount', align: 'center', customRender: ({ text }) => `满 ${text || 0} 元` },
        { title: '使用渠道', dataIndex: 'useChannel', align: 'center', customRender: ({ text, record }) => channelText(record) },
        { title: '结算价(元)', dataIndex: 'settlementPrice', align: 'center' },
        { title: '分配员工', dataIndex: 'allocationPerson', align: 'center' },
        { title: '消费订单', dataIndex: 'orderCode', align: 'center' },
        // { title: '有效期', dataIndex: 'effectiveDays', align: 'center', customRender: ({ text, record }) => (record.fixedTermFlag === 1 ? `${record.effectiveStartDate} - ${record.effectiveEndDate}` : `发放后 ${record.effectiveDays} 天`) },
        { title: '有效期', dataIndex: 'effectiveDays', align: 'center', slots: { customRender: 'effectiveDays' } },
        { title: '生产时间', dataIndex: 'produceTime', align: 'center' },
        { title: '最晚分配时间', dataIndex: 'lastedEffectiveDate', align: 'center' },
        { title: '发放时间', dataIndex: 'allocationTime', align: 'center' },
        { title: '消费时间', dataIndex: 'orderTime', align: 'center' },
        { title: '过期时间', dataIndex: 'expireTime', align: 'center' },
        { title: '作废时间', dataIndex: 'cancelTime', align: 'center' },
        { title: '状态', dataIndex: 'status', align: 'center', customRender: ({ text }): string => cardStatusEnum[text as keyof typeof cardStatusEnum] },
        { title: '操作', dataIndex: 'action', width: '110px', align: 'center', slots: { customRender: 'action' }, fixed: 'right' },
      ];
      return basic;
    });
    // 作废
    const onInvalid = (row: any) => {
      Modal.confirm({
        title: '操作确认',
        content: h('div', null, [
          h('p', null, '确认作废以下员工折扣卡 '),
          h('div', null, [
            h('label', null, '折扣卡ID: '),
            h('span', null, `${row.discountCardId}`),
          ]),
          h('div', null, [
            h('label', null, '批次ID: '),
            h('span', null, `${row.batchId}`),
          ]),
          h('div', null, [
            h('label', null, '所属企业: '),
            h('span', null, `${row.enterpriseName}`),
          ]),
          h('div', null, [
            h('label', null, '使用渠道: '),
            h('span', null, `${channelText(row)}`),
          ]),
          h('div', null, [
            h('label', null, '活动名称: '),
            h('span', null, `${row.activityName}`),
          ]),
          h('div', null, [
            h('label', null, '面额: '),
            h('span', null, `满 ${row.fullAmount} 元减 ${row.reduceAmount} 元`),
          ]),
        ]),
        okText: '确认',
        cancelText: '取消',
        onOk() {
          request.post(createApiUrl('/newlinkSass/discountCard/cancel'), { discountCardId: row.id })
            .then(() => {
              onRefreshTable();
              Modal.success({
                title: '操作成功',
                content: `员工折扣卡${row.discountCardId}已成功作废`,
                okText: '知道了',
                onOk() {
                  onRefreshTable();
                },
              });
            }).catch(e => {
              message.error(e.message);
            });
        },
      });
    };

    const showConfirmModal = ref<boolean>(false);
    const closeConfirmModal = (isRefresh = false) => {
      showConfirmModal.value = false;
      if (isRefresh) {
        onRefreshTable();
      }
    };

    const confirmInfo = ref({});
    const onDistribute = (row: Record<string, any>) => {
      confirmInfo.value = row;
      showConfirmModal.value = true;
    };

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const exportParams = reactive<{
      timeRange: Moment[];
    }>({
      timeRange: [],
    });

    const onExport = () => {
      Object.assign(exportParams, {
        timeRange: [],
      });
      confirmVisible.value = true;
    };

    let firstSelectTime: Moment | null;
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      // const params: Record<string, any> = {};
      // if (!exportParams.timeRange.length) {
      //   message.warning('请选择时间范围在导出');
      //   return;
      // }
      // if (exportParams.timeRange.length) {
      //   params.startDate = exportParams.timeRange[0].format('YYYY-MM-DD');
      //   params.endDate = exportParams.timeRange[1].format('YYYY-MM-DD');
      // }
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/discountCard/export'), { ...getParams() }, { responseType: 'blob', noEnterpriseId: true });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`折扣卡列表${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    return {
      filterParams,
      cardStatusEnum,
      channelTypeEnum,
      tableRef,
      tableColumns,
      dataPage,
      dataState,
      loadTableData,
      onRefreshTable,
      onInvalid,
      
      showConfirmModal,
      closeConfirmModal,
      onDistribute,
      confirmInfo,

      isDateDisable,
      onOpenChange,
      onCalendarChange,
      onExport,
      confirmVisible,
      exporting,
      exportParams,
      onConfirmExport,
      sldPageTitle,
    };
  },
});
