
import { computed, defineComponent, reactive, Ref, ref, UnwrapRef, onMounted, h } from 'vue';
import { settleTree } from '@/utils/illuminate';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { message, Modal } from 'ant-design-vue';
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { useState } from '@/store';
import { channelText } from '../dict';

type DataItem = {
  key: string;
  name: string;
  age: number;
  address: string;
}

type userInfoType = {
  userName: string
  userCode: string
  phone: string,
  value: string,
  departmentId: string | number,
  hasUser: boolean, // 仅用于tree node
  userList: Array<userInfoType>, // 仅用于tree node
  isLeaf: boolean, // 仅用于tree node isleaf
  dataRef: any, // 仅用于tree node isleaf
  jobNumber: string | number, // 员工工号
}
type previewInfoType = {
  personNum: number | string,
  cardTotalNum: number | string,
  frozenAmount: number | string
}
export default defineComponent({
  components: {
    PlusCircleOutlined,
  },
  props: {
    batchInfo: { type: Object, default: () => ({}) },
  },
  emits: ['close'], 
  setup(props, ctx) {
    const state = useState();
    const visible = ref<boolean>(true);
    const loading = ref<boolean>(false);
    const handleCancel = () => {
      ctx.emit('close');
    };
    const userName = ref<string>('');

    const columns = [
      { title: '姓名', dataIndex: 'userName' },
      { title: '工号', dataIndex: 'jobNumber' },
      { title: '手机号', dataIndex: 'phone' },
      { title: '部门', dataIndex: 'departmentName' },
      { title: '操作', dataIndex: 'operation', slots: { customRender: 'operation' } },
    ];
    const dataSource: Ref<userInfoType[]> = ref([]);
    const count = computed(() => dataSource.value.length + 1);
    const addUser = (user: userInfoType, flag: string) => {
      if (flag === 'node' && user.hasUser) {
        console.log('user node: ', user.userList);
        dataSource.value = [...dataSource.value, ...user.userList];
      } else if (flag === 'node' && user.isLeaf) {
        console.log('user isLeaf: ', user.dataRef);
        dataSource.value.push(user.dataRef);
      } else {
        dataSource.value.push(user);
      }
      console.log('** nishuo ** ', dataSource.value);
    };
    const onDelete = (index: number) => {
      dataSource.value.splice(index, 1);
      // dataSource.value = dataSource.value.filter(item => item.userCode !== key);
    };


    /** ------ search user ------- */
    const userList = reactive<Record<string, any>>({
      unSelect: [],
    });
    const showUserList = ref<boolean>(false);
    const getUserInfo = (val:userInfoType) => {
      if (val) {
        showUserList.value = true;
        userList.unSelect = [val];
      } else {
        showUserList.value = false;
        userList.unSelect = [];
      }
    };

    /* = ----------- = 加载搜索用的部门树 = ------------------ = */
    const changeTree = (valArr:any) => {
      valArr.forEach((item: Record<string, any>) => {
        if (Array.isArray(item.departmentList) && item.departmentList.length) {
          changeTree(item.departmentList);
        } else {
          item.departmentList = [];
        }
      });
      return valArr;
    };

    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'), { params: { enterpriseId: props.batchInfo.enterpriseId } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      const tempTree = settleTree(res.data.departmentList, { key: 'id', title: 'departmentName', value: 'id' }, 'departmentList');
      departmentList.value = tempTree;
    };
    const onLoadData = (treeNode: any) => new Promise((resolve: (value?: unknown) => void) => {
      // 有子节点，且节点里已经加载过userChildren，直接返回
      if (treeNode.dataRef.children && treeNode.dataRef.hasUser) {
        resolve();
        return;
      }
      // 根据部门获取人员列表 最多获取200条
      const parameter = { pageNo: 1, pageNum: 1, pageSize: 200 };
      const departmentId = treeNode.dataRef.id;
      request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-user-list'),
        { params: { ...parameter, state: 'ON', enterpriseId: props.batchInfo.enterpriseId, departmentId } })
        .then(({ data }) => {
          // data.list:  id userCode userName phone jobNumber departmentId: treeNode.dataRef.id  isLeaf: true
          // { key: 'id', title: 'departmentName', value: 'id' }
          const userChildren = data?.list.map((userItem:Record<string, any>) => {
            const { id, userCode, userName, phone, departmentName, jobNumber } = userItem;
            return {
              isLeaf: true, // 无子节点
              key: `${id}-user`,
              title: userName,
              value: userCode,
              id,
              userName,
              userCode,
              phone,
              departmentId,
              departmentName,
              jobNumber,
            };
          });
          // 部分下有人，给部门打标签
          if (userChildren.length) {
            treeNode.dataRef.hasUser = true;
            treeNode.dataRef.userList = userChildren;
          }
          // 组合人和子部门
          treeNode.dataRef.children = treeNode.dataRef.children ? [...userChildren, ...treeNode.dataRef.children] : userChildren;
          departmentList.value = [...departmentList.value];
          resolve();
        }).catch(e => {
          message.error(e.message);
        });
    });

    // 下一步
    const formRef = ref();
    const formState = reactive({
      cardNumOfEachPerson: '',
      excludeRepeatRule: 1,
    });
    const rules = {
      cardNumOfEachPerson: [{ required: true, message: '请输入', trigger: 'blur', type: 'number' }],
      excludeRepeatRule: [{ required: true, message: '请选择', trigger: 'change' }],
    };
    
    /**
     * preview
     */
    const isShowPreview = ref<boolean>(false);
    const previewColumns = [
      { title: '姓名', dataIndex: 'userName' },
      { title: '工号', dataIndex: 'jobNumber' },
      { title: '手机号', dataIndex: 'phone' },
      { title: '部门', dataIndex: 'departmentName' },
      { title: '数量', dataIndex: 'quantity' },
    ];
    const previewUserList: Ref<userInfoType[]> = ref([]);
    const previewInfo = ref({});
    const batchDistributePreview = () => {
      const params = {
        discountCardRuleId: props.batchInfo.id,
        ...formState,
        userDetailList: dataSource.value,
      };
      request.post(createApiUrl('/newlinkSass/discountCard/allocationReview'), params)
        .then(({ data }) => {
          const { userDetailList, ...ohter } = data;
          previewUserList.value = data.userDetailList;
          previewInfo.value = ohter;
        }).catch((e:any) => {
          message.error(e.message);
        });
    };
    // 下一步
    const nextStep = () => {
      if (!(dataSource.value?.length)) {
        message.warning('请选择要分配的人员');
        return;
      }
      formRef.value.validate()
        .then(() => {
          isShowPreview.value = true;
          batchDistributePreview();
        });
    };
    // 上一步
    const lastStep = () => {
      isShowPreview.value = false;
      showUserList.value = false;
      userList.unSelect = [];
    };
    const batchDistribute = () => {
      loading.value = true;
      const params = {
        discountCardRuleId: props.batchInfo.id,
        userDetailList: previewUserList.value,
        ...previewInfo.value,
      };
      request.post(createApiUrl('/newlinkSass/discountCard/batchAllocation'), params)
        .then(() => {
          Modal.success({
            title: '操作成功',
            content: `已成功分配员工折扣卡 ${(previewInfo.value as previewInfoType).personNum} 人，共计 ${(previewInfo.value as previewInfoType).cardTotalNum} 张`,
            okText: '知道了',
            onOk() {
              ctx.emit('close', true);
            },
          });
        }).catch((e:any) => {
          message.error(e.message);
        }).finally(() => {
          loading.value = false;
        });
    };

    const batchInfoByApi = ref({});  
    const loadBatchCardDetailById = () => {
      request.get(createApiUrl('/newlinkSass/discountCardRule/info'), { params: { id: props.batchInfo.id } })
        .then(({ data }) => {
          batchInfoByApi.value = data || {};
        }).catch(e => {
          message.error(e.message);
        });
    };

    onMounted(() => {
      loadBatchCardDetailById();
      loadEnterpriseDepartment();
    });


    return {
      visible,
      loading,
      userName,
      handleCancel,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      batchInfoByApi,      

      columns,
      onDelete,
      dataSource,
      count,
      
      showUserList,
      userList,
      addUser,
      getUserInfo,

      departmentList,
      onLoadData,

      formRef,
      formState,
      rules,

      // === preview ===
      isShowPreview,
      previewColumns,
      previewUserList,
      nextStep,
      lastStep,
      previewInfo,
      batchDistribute,
      channelText,
    };
  },
});
